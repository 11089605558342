<template>
 <Navbar />
 <div class="main ms-3 me-3 mt-3">
  <!-- <p>Route: {{ $route.name }}</p> -->
  <router-view/>
  <div class="toast-container position-absolute bottom-0 end-0 p-3">
   <Toast v-for="message in messages" :key="message.id" :message="message" />
  </div>
 </div>
</template>

<style scoped>
</style>

<script>
 import Navbar from '@/components/Navbar.vue'
 import Toast from '@/components/Toast.vue'
 import store from '@/store/index.js'

 export default {
     name: 'Main-layout',
     components: {
         Navbar, Toast,
     },
     data(){
         return {
             messages: store.state.messages
         }
     },
 }
</script>
