<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light rounded-bottom shadow-sm">
        <div class="container-fluid">
            <!-- <a class="navbar-brand" href="#">Navbar w/ text</a> -->
            <button @click="navOpen=true" id="navbar-toggler" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div @click="collapse" class="collapse navbar-collapse" id="navbarText">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link :to="{name: 'Home'}" as="a" class="nav-link" :class="{active: $route.name=='Home'}">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'Calendar'}" as="a" class="nav-link" :class="{active: $route.name=='Calendar'}">Calendar</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'Geografija'}" as="a" class="nav-link" :class="{active: $route.name=='Geografija'}">Geografija</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'timemgmtProfiles'}" as="a" class="nav-link" :class="{active: $route.name=='Geografija'}">TimeMGMT</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'Debug'}" as="a" class="nav-link" :class="{active: $route.name=='Debug'}">Debug</router-link>
                    </li>
                </ul>
                <ul class ="navbar-nav mb-2 mb-lg-0">
                    <li v-if="!user.authenticated" class="ms-1 mt-1 nav-item"><router-link :to="{name: 'Login'}" as="a" class="nav-link"><button class="btn-outline-primary btn">Login</button></router-link></li>
                    <li v-if="!user.authenticated" class="ms-1 mt-1 nav-item"><router-link :to="{name: 'Register'}" as="a" class="nav-link"><button class="btn-outline-primary btn">Register</button></router-link></li>
                    <li v-if="user.authenticated" class="ms-1 mt-1 nav-item"><router-link :to="{name: 'User'}" as="a" class="nav-link" :class="{active: $route.name=='User'}">{{ user.user.username  }}</router-link></li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<style scoped>
</style>

<script>
 // TODO: Abstract away "single routes" (<li><router-link /></li) in its own component
 //import { getUser } from '@/components/auth.js'
 import store from '@/store/index.js'

 export default {
     name: 'Navbar',
     data(){
         return {
             navOpen: false 
         }
     },
     methods: {
         collapse(e){
             if (this.navOpen){
                 new window.bootstrap.Collapse(document.querySelector("#navbarText"))
             }
             this.navOpen=false
         }
     },
     computed: {
         user: () => {
             return store.state.user
         }
     },
 }
</script>
