/* eslint-disable no-undef */
<template>
 <div :id="concat('toast', message.id)" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
  <div class="toast-header">
   <span :style="toCSS('background-color',message.color)" class="square rounded me-2"></span>
   <strong class="me-auto">{{ message.title }}</strong>
   <small class="text-muted">{{ message.titleMuted }}</small>
   <button @click="hideToast" type="button" class="btn-close" aria-label="Close"></button>
  </div>
  <div class="toast-body">
   {{ message.message }}
  </div>
 </div>
</template>

<style scoped>
 .square {
     width: 20px;
     height: 20px;
 }
</style>

<script>
 export default {
     name: 'Toast',
     props: {
         message: {default: {id: 0, title: '', titleMuted: '', message: '', color: 'blue'}},
     },
     data() {
         return {
             toast: null,
         }
     },
     mounted(){
         this.toast = new window.bootstrap.Toast(document.querySelector(this.concat('#toast',this.message.id)), "show")
         this.toast.show()
     },
     methods: {
         concat(a, b){
             return a.toString() + b.toString()
         },
         hideToast(){
             console.log('Removing', this.message.id)
             this.toast.hide()
             setTimeout(this.$store.commit, 500, 'removeMessage', this.message.id)
         },
         toCSS(key, value){
             return key.toString() + ':' + value.toString() + ';'
         },
     }
 }
</script>
